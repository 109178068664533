import { defineNuxtRouteMiddleware, useRuntimeConfig } from 'nuxt/app';
import type { RouteLocationNormalized } from 'vue-router';

import { goToLoginPage, isLogin } from '@/utils/user.utils';

export default defineNuxtRouteMiddleware(async (_to: RouteLocationNormalized) => {
  const isMarkupMode = useRuntimeConfig().public.isMarkupMode;
  if (isMarkupMode || isLogin()) {
    return;
  }
  await goToLoginPage(_to.fullPath);
});
